export const LANDING = "/";
export const HOME = "/";
export const THANKYOU = "/thank-you";

//unused
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";

export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
