import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: "360px",
    backgroundColor: theme.palette.background.paper
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Divider />
      footer
    </React.Fragment>
  );
}

export default Footer;
