import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import pic from "../../media/teamworkSM.jpg";

const useStyles = makeStyles({
  card: {
    maxWidth: "auto",
    marginBottom: 16
  },
  media: {
    minHeight: 100
  }
});

function MediaCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={pic}
          title="المواهب العربية"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            إنضم لمنصة نوال للمواهب العربية
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" />
        </CardContent>
      </CardActionArea>
      <CardActions />
    </Card>
  );
}

export default MediaCard;
