import { createMuiTheme } from "@material-ui/core/styles";

import * as Colors from "@material-ui/core/colors";

const theme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: { main: Colors.purple[500], contrastText: "#fff" }, // Purple and green play nicely together.
    secondary: { main: "#11cb5f" } // This is just green.A700 as hex.
  },
  typography: {
    fontFamily: ["Cairo", "Roboto"].join(",")
  }
});

export default theme;
