import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

//makeStyles(theme => ({
const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  }
}));

const LoggedInButton = props => {
  const classes = useStyles();
  const store = "props";
  return (
    <React.Fragment>
      <div className={classes.menuButton}>
        <Button color="inherit">{props.value.profileUser.displayName}</Button>
      </div>
    </React.Fragment>
  );
};

export default LoggedInButton;
//{props.ContextStore.state.profileUser.displayName}
