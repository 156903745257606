import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

//makeStyles(theme => ({
const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  }
}));

const LoggedOutButton = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.menuButton}>
        <Button color="inherit">LOGIN</Button>
      </div>
    </React.Fragment>
  );
};

export default LoggedOutButton;
