import React from "react";
import MediaCard from "./MediaCard";
import SignUpPage from "../SignUp/index";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 10
  }
}));

const LandingPage = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={2}>
        <Grid item lg>
          <MediaCard />
        </Grid>
        <Grid item lg>
          <SignUpPage />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LandingPage;
