import React from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: JSON.parse(localStorage.getItem("authUser")),
        profileUser: JSON.parse(localStorage.getItem("profileUser"))
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          this.setState({ authUser });

          this.profileListner = this.props.firebase.db
            .collection("profiles")
            .doc(authUser.uid)
            .onSnapshot(doc => {
              const profileUser = doc.data();
              localStorage.setItem("profileUser", JSON.stringify(profileUser));
              this.setState({ profileUser });
            });
        } else {
          localStorage.removeItem("authUser");
          this.setState({ authUser: null });
          localStorage.removeItem("profileUser");
          this.setState({ profileUser: null });
          //remove anonymous signin for now
          // this.props.firebase.auth
          //   .signInAnonymously()
          //   .catch(error => console.log(error));
        }
      });
    }

    componentWillUnmount() {
      this.listener();
      this.profileListner();
    }
    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
