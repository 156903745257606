import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },

  paper: {
    padding: theme.spacing(3, 2)
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const ThankYouComponent = props => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="baseline"
      className={classes.root}
      spacing={2}
    >
      <Grid item xs={10}>
        <Paper className={classes.paper}>thank ThankYou</Paper>
      </Grid>
    </Grid>
  );
};

export default ThankYouComponent;
