import app from "firebase/app";
import "firebase/auth";
import "firebase/performance";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBbiYzuwPS6OMi51OoVESQeB9NC91GzuNw",
  authDomain: "nawal-51b21.firebaseapp.com",
  databaseURL: "https://nawal-51b21.firebaseio.com",
  projectId: "nawal-51b21",
  storageBucket: "nawal-51b21.appspot.com",
  messagingSenderId: "165173585702",
  appId: "1:165173585702:web:1b5106e77e27f44a"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    app.auth().languageCode = "ar";
    this.perf = app.performance();
    this.db = app.firestore();
    this.fb = app.auth.FacebookAuthProvider.PROVIDER_ID;
    this.twitter = app.auth.TwitterAuthProvider.PROVIDER_ID;
    this.email = app.auth.EmailAuthProvider.PROVIDER_ID;
    this.google = app.auth.GoogleAuthProvider.PROVIDER_ID;
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  //twitterProvider = this.twitter;
  firebaseAuth = () => app.auth();
}
export default Firebase;
