import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link as RLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { withAuthentication } from "../Session";
import { AuthUserContext } from "../Session";
import LoggedInButton from "./LoggedInButton";
import LoggedOutButton from "./LoggedOutButton";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: 12
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  titlelink: {
    flexGrow: 1,
    underline: "always"
  },
  color: "#7B1FA2"
}));

const ButtonAppBarBase = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <Link
              component={RLink}
              to={ROUTES.HOME}
              color="inherit"
              underline="none"
            >
              نوال.كوم
            </Link>
          </Typography>
          <AuthUserContext.Consumer>
            {value =>
              value.profileUser ? (
                <LoggedInButton value={value} />
              ) : (
                <LoggedOutButton />
              )
            }
          </AuthUserContext.Consumer>
        </Toolbar>
      </AppBar>
    </div>
  );
};
const ButtonAppBar = compose(withAuthentication)(ButtonAppBarBase);
export default ButtonAppBar;
//{authUser => <div>{console.log(authUser.state.authUser.uid)}</div>}
// {value =>
//   value.displayName ? (
//     <LoggedInButton value={value} />
//   ) : (
//     <LoggedOutButton />
//   )
// }
