import React, { Component } from "react";

import RTL from "./components/material";
import { withAuthentication } from "./components/Session";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { ButtonAppBar } from "./components/Layout";
import { ThemeProvider } from "@material-ui/styles";
import * as Colors from "@material-ui/core/colors";
import { theme } from "./components/material";

import { BrowserRouter as Router, Route } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import LandingPage from "./components/Layout/LandingPage";
import ThankYouComponent from "./components/Layout/ThankYou";
import Footer from "./components/Layout/Footer";

class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     authUser: null
  //   };
  // }

  render() {
    return (
      <React.Fragment>
        <Router>
          <RTL>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container maxWidth="lg">
                <Typography
                  component="div"
                  dir="rtl"
                  style={{
                    backgroundColor: Colors.purple[50],
                    height: "100vh"
                  }}
                >
                  <ButtonAppBar />
                  <Route exact path={ROUTES.LANDING} component={LandingPage} />
                  <Route path={ROUTES.THANKYOU} component={ThankYouComponent} />
                  <Footer />
                </Typography>
              </Container>
            </ThemeProvider>
          </RTL>
        </Router>
      </React.Fragment>
    );
  }
}

export default withAuthentication(App);
