import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import { Formik } from "formik";

import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(3, 2)
  },
  control: {
    padding: theme.spacing(2)
  },
  typo: {
    width: "100%"
  }
}));

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const SignUpFormBase = props => {
  const [firebaseError, firebaseErrorSet] = useState(null);
  const classes = useStyles();

  const createUser = async values => {
    try {
      const user = await props.firebase.doCreateUserWithEmailAndPassword(
        values.email,
        values.passwordOne
      );
      const displayNameChange = user.user.updateProfile({
        displayName: values.displayname
      });

      const profile = props.firebase.db
        .collection("tempProfiles")
        .doc(user.user.uid)
        .set({
          displayName: values.displayname
        });

      props.history.push(ROUTES.THANKYOU);
      return true;
    } catch (err) {
      firebaseErrorSet(true);
      return null;
    }
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="baseline"
      className={classes.root}
      spacing={2}
    >
      <Grid item>
        <Paper className={classes.paper}>
          <Formik
            initialValues={{
              displayname: "",
              email: "",
              passwordOne: "",
              passwordTwo: "",
              error: null
            }}
            onSubmit={(values, { setSubmitting }) => {
              firebaseErrorSet(false);

              createUser(values);

              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("الرجاء ادخال عنوان صحيح")
                .required("الرجاء ادخال بريد إلكتروني"),
              displayname: Yup.string().required("الرجاء ادخال اسم"),
              passwordOne: Yup.string()
                .min(6, "كلمة السر يجب أن لا تقل عن ستة أحرف")
                .required("الرجاء ادخال كلمة سر"),
              passwordTwo: Yup.string().oneOf(
                [Yup.ref("passwordOne"), null],
                "كلمة السر لا تتطابق"
              )
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  {firebaseError && (
                    <Typography
                      className={classes.typo}
                      color="error"
                      align="center"
                    >
                      يوجد حساب مسجل بنفس البريد الالكتروني المستخدم
                    </Typography>
                  )}
                  <TextField
                    id="displayname"
                    style={{ margin: 8 }}
                    placeholder="الاسم"
                    margin="normal"
                    value={values.displayname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  {errors.displayname && touched.displayname && (
                    <div className="input-feedback">{errors.displayname}</div>
                  )}

                  <TextField
                    id="email"
                    style={{ margin: 8 }}
                    placeholder="البريد الالكتروني"
                    margin="normal"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}

                  <TextField
                    id="passwordOne"
                    type="password"
                    style={{ margin: 8 }}
                    placeholder="كلمة السر"
                    margin="normal"
                    value={values.passwordOne}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                  {errors.passwordOne && touched.passwordOne && (
                    <div className="input-feedback">{errors.passwordOne}</div>
                  )}

                  <TextField
                    id="passwordTwo"
                    style={{ margin: 8 }}
                    placeholder="تأكيد كلمة السر"
                    margin="normal"
                    type="password"
                    value={values.passwordTwo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                  {errors.passwordTwo && touched.passwordTwo && (
                    <div className="input-feedback">{errors.passwordTwo}</div>
                  )}
                  <Button
                    variant="contained"
                    type="button"
                    color="default"
                    className={classes.button}
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                  >
                    إعادة
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    color="secondary"
                    className={classes.button}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    تسجيل
                  </Button>
                </form>
              );
            }}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;
export { SignUpForm, SignUpLink };
